import contentPathUrlMapper from '../src/utils/contentURLMapper';
import { fetchFeatureFlags } from '../src/utils/featureFlags';

import ESI from '../src/components/ESI';
import logControl from '../src/utils/logger-utils/logControl';

function Content({ url, query, featureFlags }) {
  const path = query.path ? query.path : 'home';
  const contentPath = contentPathUrlMapper(url, false, featureFlags);
  return (
    <>
      <ESI src={contentPath} id={`content-renderer-${path}`} />
    </>
  );
}

export async function getServerSideProps(context) {
  const { resolvedUrl, query } = context;
  const featureFlags = fetchFeatureFlags(context);

  logControl(context);

  return {
    props: {
      url: resolvedUrl,
      query,
      featureFlags,
    },
  };
}

export default Content;
